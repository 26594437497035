import React from 'react';

const LikeIcon = () => {
    return (
        <svg
            className="icon"
            width="77"
            height="84"
            viewBox="0 0 77 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M65.7992 23.0858C61.9409 16.3207 53.8838 13.8464 45.9159 15.4913C38.7408 16.9725 34.8899 22.2623 30.9299 27.7125C28.4882 27.0141 26.0749 26.2606 23.6268 25.6356C16.3592 23.7802 3.82042 29.5256 0.977984 37.9506C-2.0396 46.8946 2.36089 53.0461 8.23699 58.2364C13.6151 62.9867 20.144 66.5149 26.2116 70.5162C31.2316 73.8266 36.9093 76.2845 39.5239 82.3162C40.6727 84.9665 42.8026 84.1649 44.2039 82.1382C46.817 78.3588 49.6118 74.6564 51.7983 70.6475C58.0527 59.1798 66.9832 48.7561 67.9275 35.1262C68.2041 31.1336 67.7299 26.471 65.7992 23.0858ZM55.7789 54.8466C50.7418 61.8382 46.0046 69.034 41.0875 76.2092C32.3791 70.0257 23.6121 64.0149 15.1218 57.655C12.0825 55.4491 9.47638 52.7295 7.42792 49.6263C1.90518 40.8139 7.25619 33.7117 16.7264 32.3215C22.6464 31.4524 28.3058 31.3774 33.7047 34.1065C34.4939 34.5055 34.7936 35.7545 35.5604 36.2759C36.751 36.9768 37.9955 37.5872 39.2829 38.1016C39.4372 36.7361 40.1362 35.1169 39.6174 34.0651C38.8515 32.5118 37.2195 31.3622 35.917 29.9997C36.2975 29.2933 36.7361 28.6181 37.2286 27.9805C38.1824 25.3885 40.1149 23.2463 42.634 21.9886L42.4897 21.959C49.002 16.8122 59.6467 18.9 63.0783 26.0444C63.577 26.9996 63.8279 28.0587 63.8092 29.1297C63.1193 38.2764 61.37 47.0856 55.7789 54.8466Z"
                fill="#CF0C0C"
            />
            <path
                className="line"
                d="M33.2653 11.1362L36.632 15.6306C36.8231 15.8491 37.0926 16.0891 37.3274 16.1535C37.7187 16.2609 38.1537 16.2142 38.4976 15.8934C39.1199 15.4829 39.2033 14.5924 38.7863 13.9797L35.4197 9.48541C35.0027 8.87278 34.0981 8.79064 33.4758 9.20114C32.91 9.71016 32.7483 10.5792 33.2653 11.1362Z"
                fill="#CF0C0C"
            />
            <path
                className="line"
                d="M56.3483 1.3101L53.8674 10.0955C53.6716 10.7891 54.1235 11.5778 54.8278 11.7716C55.532 11.9653 56.3321 11.5211 56.528 10.8275L59.0089 2.04207C59.2047 1.34849 58.7528 0.559741 58.0485 0.365984C57.3442 0.172228 56.5441 0.616519 56.3483 1.3101Z"
                fill="#CF0C0C"
            />
            <path
                className="line"
                d="M74.5858 18.8942L70.0385 21.5492C69.4162 21.9597 69.198 22.7302 69.5932 23.4199C69.7625 23.7154 70.032 23.9554 70.4233 24.0628C70.7364 24.1487 71.1713 24.102 71.4716 23.9353L76.0189 21.2803C76.6412 20.8698 76.8594 20.0993 76.4642 19.4097C76.069 18.72 75.2081 18.4837 74.5858 18.8942Z"
                fill="#CF0C0C"
            />
            <path
                className="line"
                d="M67.8889 10.3089L62.487 15.3865C61.9211 15.8955 61.9378 16.7304 62.4548 17.2875C62.646 17.506 62.8808 17.5704 63.1156 17.6349C63.5852 17.7637 64.042 17.64 64.4641 17.3406L69.8661 12.263C70.4319 11.754 70.4153 10.9191 69.8982 10.362C69.2811 9.86052 68.4548 9.79986 67.8889 10.3089Z"
                fill="#CF0C0C"
            />
            <path
                className="line"
                d="M43.6858 3.36695C42.9506 3.58039 42.4976 4.28646 42.7144 5.01024L44.6696 12.1066C44.7953 12.5563 45.1995 12.9163 45.6691 13.0451C45.9039 13.1095 46.1605 13.0969 46.4171 13.0843C47.1523 12.8709 47.6053 12.1648 47.3885 11.441L45.4333 4.34467C45.1382 3.59941 44.3992 3.23055 43.6858 3.36695Z"
                fill="#CF0C0C"
            />
        </svg>
    );
};

export default LikeIcon;
