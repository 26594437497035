import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from 'project/api';
import classNames from 'classnames';
import LikeIcon from 'components/like/js/likeIcon';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Like = ({ likeCardUrl, cardId, likes, isLiked, allowLikeCards }) => {
    if (!likeCardUrl) return null;
    const [state, setState] = useState({
        status: 'Unlike',
        likeCount: likes,
        isLikedStatus: isLiked,
    });

    const onClick = () => {
        if (!allowLikeCards) {
            return;
        }
        api.get(`${likeCardUrl}?cardId=${cardId}`).then((response) => {
            setState({ ...response.data, isLikedStatus: response.data.status === 'Like' });
        });
    };

    const { likeCount, isLikedStatus } = state;
    const likeMod = classNames({
        'is-liked': isLikedStatus,
    });
    return (
        <>
            {allowLikeCards ? (
                <div className={`like ${likeMod}`} onClick={onClick}>
                    <div className="like__count">
                        <span className="like__value">{likeCount}</span>
                        <LikeIcon />
                    </div>
                </div>
            ) : (
                <Tippy
                    hideOnClick={false}
                    content={
                        'Авторизуйтесь через гугль, чтобы ставить лайки. Кнопка авторизации — в шапке сайта'
                    }
                    placement={'top-end'}
                    maxWidth={250}
                >
                    <div className={`like ${likeMod}`} onClick={onClick}>
                        <div className="like__count">
                            <span className="like__value">{likeCount}</span>
                            <LikeIcon />
                        </div>
                    </div>
                </Tippy>
            )}
        </>
    );
};

Like.propTypes = {
    likeCardUrl: PropTypes.string.isRequired,
    cardId: PropTypes.number.isRequired,
    likes: PropTypes.number.isRequired,
    isLiked: PropTypes.bool.isRequired,
};

export default Like;
