/* general styles */
import 'project/general/scss/index.scss';

/* project */
import 'project/lazysizes';

/* plugins */
import 'project/general/js/what-input';

import 'components/header';
import 'components/card';
import 'components/board';
import 'components/page-overlay';
import 'components/progress-bar';
import 'components/modal-trigger';
import 'components/form';
import 'components/like';
import 'components/expandable-media';
import 'components/scroll-to-top';

/* require svg */
const files = require.context('project/general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);
