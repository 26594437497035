import React from 'react';
import PropTypes from 'prop-types';
import { cardTypes } from 'components/board/js/cardTypes';
import ImageCard from 'components/card/js/image-card';
import StickerCard from 'components/card/js/sticker-card';
import StickerAndImageCard from 'components/card/js/sticker-and-image-card';

const CardCreator = (props) => {
    switch (props.model.cardType) {
        case cardTypes.IMAGE_CARD:
            return <ImageCard {...props} />;
        case cardTypes.STICKER_CARD:
            return <StickerCard {...props} />;
        case cardTypes.STICKER_AND_IMAGE_CARD:
            return <StickerAndImageCard {...props} />;
        default:
            return <StickerCard {...props} />;
    }
};

CardCreator.propTypes = {
    model: PropTypes.object.isRequired,
};

export default CardCreator;
