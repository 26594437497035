import React from 'react';
import PropTypes from 'prop-types';

const SvgIcon = ({ name, size, additionalClass, value }) => (
    <svg
        className={`${additionalClass || ''} icon`}
        width={size[0]}
        height={size[1] || size[0]}
        focusable="false"
    >
        <>
            <use xlinkHref={`#icon-${name}`} />
            {value && (
                <text x="0" y="50" font-family="Verdana" font-size="35" fill="blue">
                    {value}
                </text>
            )}
        </>
    </svg>
);

SvgIcon.propTypes = {
    additionalClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.array.isRequired,
    value: PropTypes.number,
};

export default SvgIcon;
