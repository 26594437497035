import { DcBaseComponent } from '@deleteagency/dc';
import anime from 'animejs';
import { pageLocker } from '@deleteagency/page-locker';

export default class PageOverlayComponent extends DcBaseComponent {
    static getNamespace() {
        return 'page-overlay';
    }

    onInit() {
        this._onLoad();
        pageLocker.lock('overlay');
    }

    _onLoad = () => {
        anime({
            targets: this.refs.progress,
            width: ['0', '100%'],
            duration: Math.random() * (2600 - 1800) + 1000,
            easing: 'easeInQuart',
            complete: () => {
                this.element.classList.add('is-hidden');
                pageLocker.unlock('overlay');
            },
        });
    };
}
