import { ModalService } from '@deleteagency/modal';
import { dcFactory } from '@deleteagency/dc';
import { pageLocker } from '@deleteagency/page-locker';
import { generateBemCss } from 'project/helpers';

const modalService = new ModalService();

modalService.init({
    onModalInit: (modal) => {
        dcFactory.init(modal.element);
    },
    onModalDestroy: (modal) => {
        dcFactory.destroy(modal.element);
    },
    onBeforeFirstModalOpen: () => {
        pageLocker.lock('modal');
    },
    onAfterLastModalClose: () => {
        pageLocker.unlock('modal');
    },
    defaultModalTemplate: ({ bemList, title }) => {
        const rootCss = generateBemCss('modal', bemList);
        return `
        <div data-modal class="${rootCss}" aria-hidden="true" role="dialog">
            <div class="modal__head" data-modal-head>
                ${!!title ? `<div class="modal__title">${title}</div>` : ''}
                <button class="modal__close btn" data-modal-close type="button" aria-label="Close popup" role="button">
                    <svg class="icon modal__close-icon modal__close-icon--cross" width="16" height="16" focusable="false">
                        <use xlink:href="#icon-cross"/>
                    </svg>
                 </button>
            </div>
            <div class="modal__wrapper" data-modal-wrapper>
                <div class="modal__content" data-modal-content></div>
            </div>
        </div>
        `;
    },
});

export default modalService;
