import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'project/general/js/lazy-image';
import topRightTapeImage from '../img/top-right-tape.svg';
import topLeftTapeImage from '../img/top-left-tape.svg';
import Modal from 'project/modal/js/modal';
import SvgIcon from 'project/general/js/svg-icon';
import Like from 'components/like/js/like';

const ImageCard = ({ model, likeCardUrl, allowLikeCards }) => {
    if (!model) return null;

    const { imagePath, id, isLiked = false, likes = 0 } = model;
    const [isModalOpen, setModalOpen] = useState(false);
    const onImageClick = () => {
        setModalOpen(true);
    };
    return (
        <div className="card card--image">
            {imagePath && (
                <div className="card__media-wrapper">
                    <div className="card__media img-bg">
                        <SvgIcon size={[50, 50]} name={'image'} additionalClass={'img-bg__icon'} />
                        <LazyImage image={imagePath} onClick={onImageClick} />
                        <div className="card__expandable-media">
                            <div className="expandable-media">
                                <SvgIcon
                                    size={[15, 15]}
                                    name={'fullscreen'}
                                    additionalClass={'expandable-media__icon'}
                                />
                            </div>
                        </div>
                    </div>
                    <LazyImage
                        image={topRightTapeImage}
                        childCss={'card__tape card__tape--right'}
                    />
                    <LazyImage image={topLeftTapeImage} childCss={'card__tape card__tape--left'} />
                    <div className="card__like">
                        <Like
                            likeCardUrl={likeCardUrl}
                            cardId={id}
                            likes={likes}
                            isLiked={isLiked}
                            allowLikeCards={allowLikeCards}
                        />
                    </div>
                </div>
            )}
            <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} theme={'image'}>
                <button
                    className="modal__close btn"
                    data-modal-close
                    type="button"
                    aria-label="Close popup"
                    role="button"
                >
                    <SvgIcon size={[16, 16]} name={'cross'} />
                </button>
                <LazyImage image={imagePath} childCss={'modal__image'} ofContain={true} />
            </Modal>
        </div>
    );
};

ImageCard.propTypes = {
    model: PropTypes.shape({
        text: PropTypes.string,
        recipient: PropTypes.string,
        imagePath: PropTypes.string,
        created: PropTypes.string,
        cardType: PropTypes.string,
    }),
};

export default ImageCard;
