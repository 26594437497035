import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'project/general/js/lazy-image';
import topRightTapeImage from 'components/card/img/top-right-tape.svg';
import topLeftTapeImage from 'components/card/img/top-left-tape.svg';
import bottomCenterTapeImage from 'components/card/img/bottom-center-tape.svg';
import classNames from 'classnames';
import Like from 'components/like/js/like';
import Modal from 'project/modal/js/modal';
import SvgIcon from 'project/general/js/svg-icon';

const StickerAndImageCard = ({ model, likeCardUrl, allowLikeCards }) => {
    if (!model) return null;

    const { text, recipient, imagePath, signature, color, id, isLiked = false, likes = 0 } = model;
    const [isModalOpen, setModalOpen] = useState(false);
    const cardColorMod = classNames({
        'card--green': color === 'green',
        'card--pink': color === 'pink',
        'card--yellow': color === 'yellow',
        'card--blue': color === 'blue',
    });

    const onImageClick = () => {
        setModalOpen(true);
    };

    return (
        <div className={`card card--sticker-and-image ${cardColorMod}`}>
            {imagePath && (
                <div className="card__media-wrapper">
                    <div className="card__media img-bg">
                        <SvgIcon size={[50, 50]} name={'image'} additionalClass={'img-bg__icon'} />
                        <LazyImage image={imagePath} onClick={onImageClick} />
                        <div className="card__expandable-media">
                            <div className="expandable-media">
                                <SvgIcon
                                    size={[15, 15]}
                                    name={'fullscreen'}
                                    additionalClass={'expandable-media__icon'}
                                />
                            </div>
                        </div>
                    </div>
                    <LazyImage
                        image={topRightTapeImage}
                        childCss={'card__tape card__tape--right'}
                    />
                    <LazyImage image={topLeftTapeImage} childCss={'card__tape card__tape--left'} />
                    <LazyImage
                        image={bottomCenterTapeImage}
                        childCss={'card__tape card__tape--bottom'}
                    />
                </div>
            )}

            <div className="card__content">
                <h2 className="card__title title-type-3">{recipient}</h2>
                <p className="card__text initial-mt-15">{text}</p>
                <h3 className="card__signature title-type-6 initial-mt-15">{signature}</h3>
                <div className="card__like">
                    <Like
                        likeCardUrl={likeCardUrl}
                        cardId={id}
                        likes={likes}
                        isLiked={isLiked}
                        allowLikeCards={allowLikeCards}
                    />
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} theme={'image'}>
                <button
                    className="modal__close btn"
                    data-modal-close
                    type="button"
                    aria-label="Close popup"
                    role="button"
                >
                    <SvgIcon size={[16, 16]} name={'cross'} />
                </button>
                <LazyImage image={imagePath} childCss={'modal__image'} ofContain={true} />
            </Modal>
        </div>
    );
};

StickerAndImageCard.propTypes = {
    model: PropTypes.shape({
        text: PropTypes.string,
        recipient: PropTypes.string,
        imagePath: PropTypes.string,
        created: PropTypes.string,
        cardType: PropTypes.string,
        color: PropTypes.string,
    }),
};

export default StickerAndImageCard;
