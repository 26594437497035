import { DcBaseComponent } from '@deleteagency/dc';
import { modalService } from 'project/modal';

class ModalTriggerComponent extends DcBaseComponent {
    constructor(element, options) {
        super(element);
        this.modal = null;
        this.options = { ...this.options, ...options };
        this.addListener(this.element, 'click', this.onClick);
    }

    static getNamespace() {
        return 'modal-trigger';
    }

    onInit() {}

    onDestroy() {
        if (this.modal) {
            this.modal.destroy();
            this.modal = null;
        }
    }

    onClick = (e) => {
        this.open();
        e.preventDefault();
    };

    open() {
        if (this.isOpenning) return;

        this.isOpenning = true;

        this.getModal()
            .then((modal) => {
                try {
                    modal.open();
                    this.isOpenning = false;
                } catch (error) {
                    console.log(error);
                }
            })
            .catch((error) => {
                this.isOpenning = false;
                console.log(error);
            });
    }

    getModal() {
        if (this.modal) {
            return Promise.resolve(this.modal);
        }

        return this.getTarget()
            .then((data) => {
                try {
                    this.modal = modalService.create(data, { ...this.options });
                    return this.modal;
                } catch (error) {
                    return Promise.reject(error);
                }
            })
            .catch((error) => Promise.reject(error));
    }

    getAsyncTargetUrl() {
        return this.element.getAttribute('href') || this.getChildAttribute(this.element, 'href');
    }

    getTarget() {
        if (this.options.content) {
            return Promise.resolve(this.options.content);
        }
        if (this.options.targetSelector) {
            const container = document.querySelector(this.options.targetSelector);
            return Promise.resolve(container);
        }

        console.error('Modal Trigger has no content');
        return Promise.resolve(null);
    }
}

export default ModalTriggerComponent;
