import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'project/general/js/lazy-image';
import classNames from 'classnames';
import Like from 'components/like/js/like';

const StickerCard = ({ model, likeCardUrl, allowLikeCards }) => {
    if (!model) return null;
    const { text, recipient, imagePath, signature, color, id, isLiked = false, likes = 0 } = model;

    const cardColorMod = classNames({
        'card--green': color === 'green',
        'card--pink': color === 'pink',
        'card--yellow': color === 'yellow',
        'card--blue': color === 'blue',
    });
    return (
        <div className={`card card--sticker ${cardColorMod === '' ? 'card--green' : cardColorMod}`}>
            {imagePath && (
                <div className="card__media">
                    <LazyImage image={imagePath} />
                </div>
            )}

            <div className="card__content">
                <h2 className="card__title title-type-3">{recipient}</h2>
                <p className="card__text initial-mt-15">{text}</p>
                {signature && (
                    <h3 className="card__signature title-type-6 initial-mt-15">{signature}</h3>
                )}
                <div className="card__like">
                    <Like
                        likeCardUrl={likeCardUrl}
                        cardId={id}
                        likes={likes}
                        isLiked={isLiked}
                        allowLikeCards={allowLikeCards}
                    />
                </div>
            </div>
        </div>
    );
};

StickerCard.propTypes = {
    model: PropTypes.shape({
        text: PropTypes.string,
        recipient: PropTypes.string,
        imagePath: PropTypes.string,
        created: PropTypes.string,
        cardType: PropTypes.string,
        id: PropTypes.number,
    }),
};

export default StickerCard;
