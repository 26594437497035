import { DcBaseComponent } from '@deleteagency/dc';

export default class BoardComponent extends DcBaseComponent {
    static getNamespace() {
        return 'input-file';
    }

    onInit() {
        this.addListener(this.refs.input, 'change', this.onChange);
    }

    onChange = () => {
        this.refs.filePath.innerHTML = this.refs.input.value.split('\\').pop();
    };
}
