export const generateBemCss = (bemName, bemList) => {
    let bemCss = '';

    if (Array.isArray(bemList)) {
        bemCss = bemList
            .filter((bemModifier) => bemModifier)
            .map((bemModifier) => `${bemName}--${bemModifier}`)
            .join(' ');
    }

    return bemCss ? `${bemName} ${bemCss}` : bemName;
};

export const getDistanceBetweenTwoPoints = (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1);
