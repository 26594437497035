import { DcBaseComponent } from '@deleteagency/dc';
import ReactDOM from 'react-dom';
import React from 'react';
import Board from './board-component';

export default class BoardComponent extends DcBaseComponent {
    static getNamespace() {
        return 'board';
    }

    onInit() {
        const filteredCards = this.options.cards.filter((card) => card.cardType !== 'hide');
        ReactDOM.render(
            <Board
                cards={filteredCards}
                likeCardUrl={this.options.likeCardUrl}
                {...this.options}
            />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.refs.element);
    }
}
