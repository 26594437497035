import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { modalService } from 'project/modal';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.modal = null;
        this.container = document.createElement('div');
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.open();
        }
    }

    open() {
        if (!this.modal) {
            this.modal = modalService.create(this.container, {
                bemList: [this.props.theme],
                onClose: this.onModalClose,
                title: this.props.title,
            });
        }
        this.modal.open();
    }

    close() {
        this.modal.close();
    }

    onModalClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            if (this.props.isOpen) {
                this.open();
            } else {
                this.close();
            }
        }
    }

    componentWillUnmount() {
        if (this.modal) {
            this.modal.destroy();
        }
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.container);
    }
}

Modal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    theme: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
};

export default Modal;
