import React from 'react';
import PropTypes from 'prop-types';
import Shuffle from 'shufflejs';
import ArrayHelper from 'foundation/helpers/array-helper.ts';
import CardCreator from 'components/board/js/card-creator';

const ITEMS_PER_PAGE = 5;

class Board extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: [],
            sliderType: false,
        };

        this.masonry = null;
        this.isMasonryInited = false;
        this.masonryContainer = React.createRef();
        this.container = React.createRef();
    }

    chunkArray = (array, size) => {
        const results = [];
        const _array = array.slice(0);

        while (_array.length) {
            results.push(_array.splice(0, size));
        }

        return results;
    };

    componentDidMount() {
        this._initMasonry();
        window.addEventListener('scroll', this.scrollHandler);
    }

    componentDidUpdate() {
        if (this.masonry) {
            this.masonry.resetItems();
        }
    }

    _initMasonry() {
        if (this.isMasonryInited) return;

        const chunkedItems = this.chunkArray(this.props.cards, ITEMS_PER_PAGE);

        if (ArrayHelper.isEmpty(chunkedItems)) return;

        this.setState(
            () => ({
                cards: chunkedItems[0],
                currentPage: 0,
                sliderType: false,
                chunkedItems,
            }),
            () => {
                this.masonry = new Shuffle(this.masonryContainer.current, {});
                this.isMasonryInited = true;
            }
        );
    }

    _getNextPage = () => {
        const { currentPage, chunkedItems } = this.state;

        const cards = chunkedItems[currentPage + 1];

        this.setState(() => ({
            cards: [...this.state.cards, ...cards],
            currentPage: currentPage + 1,
        }));
    };

    _destroyMasonry() {
        if (this.masonry) {
            this.masonry.destroy();
            this.masonry = null;
            this.isMasonryInited = false;
        }
    }

    scrollHandler = () => {
        if (this.state.currentPage !== this.state.chunkedItems.length - 1) {
            const bottom = this.container.current.getBoundingClientRect().bottom;
            if (window.innerHeight - bottom >= -150) {
                this._getNextPage();
            }
        }
    };

    onLoadMoreClick = () => {
        this._getNextPage();
    };

    componentWillUnmount() {
        this._destroyMasonry();
    }

    render() {
        const { cards } = this.state;
        return (
            <div className="board__container" ref={this.container}>
                {ArrayHelper.isEmpty(cards) && (
                    <p className="initial-mt-30">Sorry, there are no cards</p>
                )}

                <div className="board__inner">
                    <div className="board__row row row--tiles" ref={this.masonryContainer}>
                        {cards.map((card, i) => {
                            return (
                                <div className="col-tablet-4" key={`id-${i}`}>
                                    <CardCreator
                                        model={card}
                                        likeCardUrl={this.props.likeCardUrl}
                                        allowLikeCards={this.props.allowLikeCards}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

Board.propTypes = {
    cards: PropTypes.array.isRequired,
    likeCardUrl: PropTypes.string.isRequired,
};

export default Board;
