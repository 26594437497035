import { DcBaseComponent } from '@deleteagency/dc';
import anime from 'animejs';

export default class ScrollToTop extends DcBaseComponent {
    static getNamespace() {
        return 'scroll-to-top';
    }

    onInit() {
        this.addListener(this.element, 'click', this._scrollToTop);
    }

    _scrollToTop = () => {
        anime({
            targets: document.documentElement,
            scrollTop: 0,
            duration: 300,
            easing: 'easeInOutSine',
        });

        anime({
            targets: document.body,
            scrollTop: 0,
            duration: 300,
            easing: 'easeInOutSine',
        });
    };
}
