import React from 'react';

const LazyImage = ({ image, childCss, onLoad, alt, ofContain = false, ...rest }) => {
    if (!image) return null;
    return (
        <img
            className={`${childCss} lazyload ${ofContain ? 'of-contain' : 'of-cover'}`}
            key={image}
            alt={alt}
            data-srcset={image}
            data-sizes="auto"
            onLoad={onLoad}
            {...rest}
        />
    );
};

LazyImage.defaultProps = {
    childCss: '',
};

export default LazyImage;
